"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MARKER = exports.MARKER_CLUSTERER = exports.MAP = void 0;

/**
 * Constants for Props Key
 * @author terencewu
 */
var MAP = 'SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED';
exports.MAP = MAP;
var MARKER_CLUSTERER = 'SECRET_MARKER_CLUSTERER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED';
exports.MARKER_CLUSTERER = MARKER_CLUSTERER;
var MARKER = 'SECRET_MARKER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED';
exports.MARKER = MARKER;